<template>
    <div class="containerWeb" style="height:100%;padding-top:88px;padding-bottom:64px;">
		<Header style="margin-top:-88px;position: relative;"></Header>
		<div style="min-height:100%;width:100%;backgroup:#fdfdfd;">
			<Search :dataEPSobj="dataEPSobj" :bannerback="bannerback"></Search>
			<div class="hot-nav">
				<div class="label-nav">热门导航：</div>
				<ul>
					<li v-for="(item,key) in hotNavs" :key="key" :class="{'new':item.IS_HOT===1}">
						<a :href="item.MENU_URL" target="_blank" rel="noopener noreferrer">{{item.MENU_NAME}}</a>
						<span class="EPS-hot-1" v-if="item.IS_HOT===1"></span>
					</li>
				</ul>
			</div>
			<div class="cubes">
				<div class="eps-cubes">
					<div class="color-bar"></div>
					<div class="block-name">宏观数据</div>
					<div class="groups">
						<template v-for="(item,key) in epsData" >
							<div class="cube-group" v-if="!item.isNotHome" :key="key" >
								<div class="group-name">{{item.groupName}}</div>
								<div class="cube-list">
									<template v-for="(i,k) in item.cubes">
										<div class="cube-item" v-if="!i.isHome" :key="k" :class="{'break':i.break,'block':i.type==='block'}">
											<a :href="epsUrl+'#/datas_data?cubeId='+i.id" target="_blank" rel="noopener noreferrer" :class="{'more': i.type=='more'}">
												{{i.cubeName}}
												<span class="new-tag" v-if="i.isNew"></span>
											</a>
										</div>
									</template>
								</div>
							</div>
						</template>
					</div>
					<div class="line"></div>
					<div class="block-name">市县数据</div>
					<div class="groups">
						<template v-for="(item,key) in cityCubes" >
						<div class="cube-group last" v-if="!item.isNotHome" :key="key" >
							<div class="group-name">{{item.groupName}} <span class="new-tag" v-if="item.isNew"></span></div> 
							<div class="cube-list">
								<template v-for="(i,k) in item.cubes">
									<div class="cube-item" v-if="!i.isHome" :key="k" :class="{'break':i.break,'block':i.type==='block'}">
										<a :href="epsUrl+'#/datas_data?cubeId='+i.id" target="_blank" rel="noopener noreferrer" :class="{'more': i.type=='more'}">
											{{i.cubeName}}
											<span class="new-tag" v-if="i.isNew"></span>
										</a>
									</div>
								</template>
							</div>
						</div>
					</template>
					</div>
					<div class="line"></div>
					<div class="end">新概念数据 <span class="new-tag" ></span></div>
					<div class="groups">
						<template v-for="(item,key) in NewdataCubes" >
						<div class="cube-group " v-if="!item.isNotHome" :key="key">
							<div class="cube-list">
								<template v-for="(i,k) in item.cubes">
									<div class="cube-item" v-if="!i.isHome" :key="k" :class="{'break':i.break,'block':i.type==='block'}">
										<a :href="epsUrl+'#/datas_data?cubeId='+i.id" target="_blank" rel="noopener noreferrer">
											{{i.cubeName}}
											<span class="new-tag" v-if="i.isNew"></span>
										</a>
									</div>
								</template>
							</div>
						</div>
					</template>
					</div>
				</div>
				<div class="other-cubes">
					<div class="wg-cubes">
						<div class="color-bar"></div>
						<div class="block-name">中微观数据</div>
						<div class="groups">
							<div class="cube-group" v-for="(item,key) in weiguanData" :key="key">
								<div class="group-name">
									<a v-if="item.link==='weiguan'" href="http://microdata.sozdata.com/" target="_blank" rel="noopener noreferrer">{{item.groupName}}</a>
									<a v-else href="javascript:;">{{item.groupName}}</a>
								</div>
								<div class="cube-list">
									<div class="cube-item" v-for="(i,k) in item.cubes" :key="k" :class="{'break':i.break}">
										<a v-if="i.link" :href="i.link" target="_blank" rel="noopener noreferrer" :style="i.style">
											{{i.cubeName}}
											<span v-if="i.isNew" class="new-tag"></span>
										</a>
										<a v-else href="javascript:;" @click="tipsMessage">
											{{i.cubeName}}
											<span v-if="i.isNew" class="new-tag"></span>
										</a>										
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cube-pic">
						<Carousel autoplay arrow="never" :autoplay-speed="4000">		
							<!-- <CarouselItem :key="0" style="width:400px;height:138px;overflow:hidden;">
								<div style="width:400px;height:138px;overflow:hidden;">
									<a style="display:block;width:400px;height:138px;overflow:hidden;" href="https://zhitu.sozdata.com/#/site_race_detail?id=195" target="_blank" rel="noopener noreferrer">
										<img src="~@/assets/adv2.png" alt="">
									</a>
								</div>
							</CarouselItem>				 -->
							<CarouselItem :key="1" style="width:400px;height:138px;overflow:hidden;">
								<div style="width:400px;height:138px;overflow:hidden;">
									<a style="display:block;width:400px;height:138px;overflow:hidden;" href="https://zhitu.sozdata.com/" target="_blank" rel="noopener noreferrer">
										<img src="~@/assets/1-800.png" alt="">
									</a>
								</div>
							</CarouselItem>
							<CarouselItem :key="2" style="width:400px;height:138px;overflow:hidden;">
								<div style="width:400px;height:138px;overflow:hidden;">
									<a style="display:block;width:400px;height:138px;overflow:hidden;" href="http://jimeng.sozdata.com/" target="_blank" rel="noopener noreferrer">
										<img src="~@/assets/jmlb.png" alt="">
									</a>
								</div>
							</CarouselItem>
							<CarouselItem :key="3" style="width:400px;height:138px;overflow:hidden;">
								<div style="width:400px;height:138px;overflow:hidden;">
									<a style="display:block;width:400px;height:138px;overflow:hidden;" href="http://cnrrd.sozdata.com/" target="_blank" rel="noopener noreferrer">
										<img src="~@/assets/qyjj-carousel.jpg" alt="">
									</a>
								</div>
							</CarouselItem>
						</Carousel>
					</div>
					<!-- <div class="public-cubes">
						<div class="color-bar"></div>
						<div class="block-name">
							<router-link to="/publicResource" style="color:#000;" target="_blank">中国公共调查数据</router-link>
						</div>
						<div class="cube-list">
							<div class="cube-item" v-for="(i,k) in publicSearchData" :key="k">
								<router-link :to="{path:'/publicResource',query:{id:i.id}}" target="_blank">{{i.cubeName}}</router-link>
							</div>
						</div>
					</div> -->

					<div class="wg-cubes">
						<div class="color-bar"></div>
						<div class="block-name">专项学术平台</div>
						<div class="groups">
							<div class="cube-group">
								<div class="group-name">
									<a href="https://zhitu.sozdata.com/" target="_blank" rel="noopener noreferrer">知图平台</a>
								</div>
								<div class="cube-list">
									<div class="cube-item">
										<a href="http://zhitu.sozdata.com/#/vote" target="_blank" rel="noopener noreferrer">投稿分析</a>
									</div>
									<div class="cube-item">
										<a href="https://zhitu.sozdata.com/#/site_source" target="_blank" rel="noopener noreferrer">信息素养</a>
									</div>
									<div class="cube-item">
										<a href="https://zhitu.sozdata.com/#/race_index" target="_blank" rel="noopener noreferrer">学赛后台</a>
									</div>
									<div class="cube-item">
										<a href="https://zhitu.sozdata.com/#/view/index?classifyId=1&sortType=1" target="_blank" rel="noopener noreferrer">图情视界</a>
									</div>
								</div>
							</div>							
						</div>
					</div>

					<div class="wg-cubes" style="margin-top:23px;">
						<div class="color-bar"></div>
						<div class="block-name">数据管理系统</div>
						<div class="groups">
							<div class="cube-group">
								<div class="group-name">
									<a href="http://jimeng.sozdata.com/" target="_blank" rel="noopener noreferrer">搜知计蒙</a>
								</div>
								<div class="cube-list">
									<div class="cube-item">
										<a href="http://jimeng.sozdata.com/index.html" target="_blank" rel="noopener noreferrer">数据管理</a>
									</div>
									<div class="cube-item">
										<a href="http://jimeng.sozdata.com/index.html" target="_blank" rel="noopener noreferrer">数据共享</a>
									</div>
									<div class="cube-item">
										<a href="http://jimeng.sozdata.com/index.html" target="_blank" rel="noopener noreferrer">数据利用</a>
									</div>
									<div class="cube-item">
										<a href="http://jimeng.sozdata.com/index.html" target="_blank" rel="noopener noreferrer">多人协作</a>
									</div>
								</div>
							</div>							
						</div>
					</div>

				</div>
				<div class="clearfix"></div>
			</div>


			<div class="evaluate-download-wrap">
				<div class="evaluate-download">
					<div class="evaluate">						
						<div class="block-name">
							EPS DATA 学术价值评价 <a href="https://datav.aliyuncs.com/share/b6b4763bd03e95c710167f24ae35706e" class="link" target="_blank" rel="noopener noreferrer" title="
引用EPS DATA发文数据统计"><img src="~@/assets/view.png" alt=""></a>
							<div class="line"></div>
						</div>
						<div class="desc">
							EPS DATA为高校、科研机构的师生及研究人员提供简单、专业、高品质的数据服务，年度数据平均下载量达10亿条，大量引用EPS DATA数据的论文被发表在国内外的顶级期刊上，为相关领域的学术研究提供了强大的数据支撑，充分反映了EPS DATA数据的学术价值。EPS DATA全面、权威的数据特点得到了广大用户的一致好评。
						</div>
						<div class="pics">
							<vue-seamless-scroll :data="qikans" class="seamless-warp"  :class-option="classOption2">
								<div class="pic-item" v-for="(item,key) in qikans" :key="key">
									<router-link :to="{path:'/Paperdetails',query:{id:item.id,tab:1}}" target="_blank">
										<img :src="item.pic" alt="">
									</router-link>
								</div>
							</vue-seamless-scroll>
							<div class="clearfix"></div>
						</div>
						<Button type="primary" class="more-btn" shape="circle" style="border-color:#2a83d3;background:#fff;">
							<router-link to="/thesis" target="_blank">
							EPS DATA 更多学术成果
							</router-link>
						</Button>
					</div>
					<div class="download">						
						<div class="block-name">
							EPS DATA 数据下载排行
							<div class="line"></div>
						</div>
						<div class="roll-nums">
							<div class="label-name">年度下载</div>
							<div class="selects">
								<Select v-model="paperYearsObj" icon="arrow-down-b" style="background:none;border:none;width:90px;" @on-change="selectedProvinceObj">
									<Option v-for="item in dataTopList" :value="item.name" :key="item.id">{{ item.name }}年</Option>
								</Select>
							</div>
							<Rollnumber class="cleft" :numStr="rollNum"></Rollnumber>
							<div class="dept cleft">条</div>
							<div class="clearfix"></div>
						</div>

						<div class="sorts">
							<div class="groupChart" v-if="dataTopYearsObj=='2019'">
								<ul class="group_item">
									<li class="groupli clear" v-for="(v,k) in lineDataList2019" :key="k">
										<div class="numchart cleft">
										<p class="gwicon" :class="'top'+v.TOP">
											<span class="fonts cleft">TOP</span>
											<span class="numsfont cleft">{{v.TOP}}</span>
										</p>
										</div>
										<a
										class="titchart cleft"
										:title="v.NAME"
										:href="epsUrl+'transform.html?cubeId='+v.childCubeId" target="_blank" rel="noopener noreferrer"
										>{{v.NAME}}</a>
										<div class="linechart cleft">
										<div class="lineback" :style="{width:v.sum+'%'}"></div>
										</div>
										<div class="icochart cright">
										{{thousandSplit(v.SUMQ)}}
										</div>
									</li>
								</ul>
							</div>
							<div class="groupChart" v-else-if="dataTopYearsObj=='2018'">
								<ul class="group_item">
									<li class="groupli clear" v-for="(v,k) in lineDataList2018" :key="k">
										<div class="numchart cleft">
										<p class="gwicon" :class="'top'+v.TOP">
											<span class="fonts cleft">TOP</span>
											<span class="numsfont cleft">{{v.TOP}}</span>
										</p>
										</div>
										<a
										class="titchart cleft"
										:title="v.NAME"
										:href="epsUrl+'transform.html?cubeId='+v.childCubeId" target="_blank" rel="noopener noreferrer"
										>{{v.NAME}}</a>
										<div class="linechart cleft">
										<div class="lineback" :style="{width:v.sum+'%'}"></div>
										</div>
										<div class="icochart cright">{{thousandSplit(v.SUMQ)}}</div>
									</li>
								</ul>
							</div>
							<div class="groupChart" v-else>
								<ul class="group_item">
									<li class="groupli clear" v-for="(v,k) in lineDataList" :key="k">
										<div class="numchart cleft">
										<p class="gwicon" :class="'top'+v.TOP">
											<span class="fonts cleft">TOP</span>
											<span class="numsfont cleft">{{v.TOP}}</span>
										</p>
										</div>
										<a
										class="titchart cleft"
										:title="v.NAME"
										:href="epsUrl+'transform.html?cubeId='+v.childCubeId" target="_blank" rel="noopener noreferrer"
										>{{v.name}}</a>
										<div class="linechart cleft">
										<div class="lineback" :style="{width:v.sum+'%'}"></div>
										</div>
										<div class="icochart cright">
										{{thousandSplit(v.SUMQ)}}
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="clearfix"></div>
				</div>
			</div>

			
			<div class="products-reports-wrap">
				<div class="products-reports">
					<div class="products">
						<div v-if="productApplicatList[0]">
							<div class="label">产品应用</div>
							<div class="pic">
								<img src="~@/assets/product-pic.png" alt="">
							</div>
							<a :href="'http://edp.epsnet.com.cn/html/'+productApplicatList[0].newsurl" target="_blank" rel="noopener noreferrer" class="title">{{productApplicatList[0].title}}</a>
							<div class="desc">{{productApplicatList[0].intro}}</div>
							<router-link to="/Morelist/1/1" class="link" target="_blank">浏览更多</router-link>
						</div>						
					</div>

					<div class="reports">
						<div v-if="analysisReportList[0]">
							<div class="label">分析报告</div>
							<div class="pic">
								<img src="~@/assets/report-pic.png" alt="">
							</div>
							<a :href="'http://edp.epsnet.com.cn/html/'+analysisReportList[0].newsurl" target="_blank" rel="noopener noreferrer" class="title">{{analysisReportList[0].title}}</a>
							<div class="desc">{{analysisReportList[0].intro}}</div>
							<router-link to="/Morelist/2/1" class="link" target="_blank">浏览更多</router-link>
						</div>
					</div>
					
					<div class="toutiao">
						<div class="label">产品动态</div>
						<div class="list">
							<div class="list-item" v-for="(item,key) in productTrendsList" :key="key" :class="{'last':key===4}">
								<div class="date">{{item.pubdate}}</div>
								<span class="dot"></span>
								<a :href="'http://edp.epsnet.com.cn/html/'+item.newsurl" target="_blank" rel="noopener noreferrer" class="name">{{item.title}}</a>
							</div>
							<router-link to="/Morelist/4/1" class="link" target="_blank">浏览更多</router-link>
						</div>
					</div>
					<div class="clearfix"></div>
				</div>
			</div>
			<div class="carousel">
				<Carousel autoplay arrow="never" :autoplay-speed="4000">
					<CarouselItem v-for="(item,key) in newSideList" :key="key" style="width:1200px;height:200px;overflow:hidden;">
						<div style="width:1200px;height:200px;overflow:hidden;">
							<a style="display:block;width:1200px;height:200px;overflow:hidden;" :href="item.LINKURL" target="_blank" rel="noopener noreferrer">
								<img style="width:1200px;height:200px;" :src="item.IMAGEURL?baseBannerUrl+item.IMAGEURL:''" alt="">
							</a>
						</div>
					</CarouselItem>
				</Carousel>
			</div>
			<div class="datas">
				<div class="data-update">
					<div class="title">数据更新<div class="line"></div></div>
					<div class="list">
						<vue-seamless-scroll :data="dataupdateList" class="seamless-warp"  :class-option="classOption">
							<div class="item" v-for="(item,key) in dataupdateList" :key="key">
								<div class="year">{{item.calender_lastdate}}</div>
								<div class="date">{{item.lastmodtime}}</div>
								<div class="split"></div>
								<div class="cube-name">{{item.topdbname}}</div>
								<div class="freq">{{item.freq}}数据</div>
								<div class="clearfix"></div>
							</div>
						</vue-seamless-scroll>
						
					</div>
				</div>
				<div class="data-update">
					<div class="title">全网首发<div class="line"></div></div>
					<div class="list">						
						<vue-seamless-scroll :data="firstDataList" class="seamless-warp"  :class-option="classOption">
							<div class="item" v-for="(item,key) in firstDataList" :key="key">
								<div class="year">{{item.calender_lastdate}}</div>
								<div class="date">{{item.lastmodtime}}</div>
								<div class="split"></div>
								<div class="cube-name">{{item.dbname}}</div>
								<div class="freq">{{item.freq}}数据</div>
								<div class="clearfix"></div>
							</div>
						</vue-seamless-scroll>
					</div>
				</div>
				<div class="products">
					<div class="title">产品系列<div class="line"></div></div>
					<div class="product-list">
						<a class="item" href="https://olap.epsnet.com.cn/#/login" target="_blank" rel="noopener noreferrer">
							<span class="icons EPS-eps"></span>EPS数据平台
						</a>
						<a class="item" href="http://microdata.sozdata.com/" target="_blank" rel="noopener noreferrer">
							<span class="icons EPS-weiguan"></span>中国微观经济数据查询系统
						</a>
						<a class="item" href="https://zhitu.sozdata.com/" target="_blank" rel="noopener noreferrer">
							<span class="icons EPS-zhitu"></span>知图平台
						</a>
						<a class="item" href="http://jimeng.sozdata.com/" target="_blank" rel="noopener noreferrer">
							<span class="icons EPS-data"></span>搜知计蒙数据管理系统
						</a>
						<a class="item" href="http://cnrrd.sozdata.com/" target="_blank" rel="noopener noreferrer">
							<span class="icons EPS-backstage"></span>中国区域研究数据支撑平台
						</a>
						<a class="item" href="http://kdd.epsnet.com.cn/" target="_blank" rel="noopener noreferrer">
							<span class="icons EPS-zhishifuwu"></span>知识服务平台
						</a>
						<a class="item" href="http://yreb.sozdata.com/" target="_blank" rel="noopener noreferrer">
							<span class="icons EPS-changjiang"></span>长江经济带大数据平台
						</a>
						<a class="item" href="http://crod.epsnet.com.cn/" target="_blank" rel="noopener noreferrer">
							<span class="icons EPS-laoqu"></span>中国革命老区大数据平台
						</a>
					</div>
				</div>
				<div class="clearfix"></div>
			</div>
			<Footer />
		</div>

		<div class="fixed-nav">
			<div class="nav-list">
				<div class="nav-item" :class="{'on':navIndex===1}" @click="setFixedNav(1)">
					<div class="icon-wrap">
						<span class="icon EPS-data"></span>
					</div>
					<div class="name">数据导航</div>
				</div>
				<div class="nav-item" :class="{'on':navIndex===2}" @click="setFixedNav(2)">
					<div class="icon-wrap">
						<span class="icon EPS-value"></span>
					</div>
					<div class="name">学术价值</div>
				</div>
				<div class="nav-item" :class="{'on':navIndex===3}" @click="setFixedNav(3)">
					<div class="icon-wrap">
						<span class="icon EPS-questions"></span>
					</div>
					<div class="name">新闻动态</div>
				</div>
				<div class="nav-item" :class="{'on':navIndex===4}" @click="setFixedNav(4)">
					<div class="icon-wrap">
						<span class="icon EPS-application"></span>
					</div>
					<div class="name">产品应用</div>
				</div>
				<div class="nav-item" :class="{'on':navIndex===5}" @click="setFixedNav(5)">
					<div class="icon-wrap">
						<span class="icon EPS-refresh"></span>
					</div>
					<div class="name">数据更新</div>
				</div>
			</div>
		</div>
		<!-- <div class="fixed-adv" v-show="showAdv">
			<a-icon type="close-circle" theme="filled" @click="showAdv=false" />
			<a href="https://zhitu.sozdata.com/#/site_race_detail?id=195" target="_blank" rel="noopener noreferrer">
				<img src="~@/assets/adv.png" alt="">
			</a>
		</div> -->
		<a-modal :visible="visible" :width="750" :footer="null" maskClosable centered @cancel="visible=false">
			<div class="state">
				<h1 class="s-title">公司官方声明</h1>
				<div class="s-content">近日，我公司接到多起求职者反馈，有不法分子假冒我公司名义，利用伪造的公司信息及标识，在QQ平台上建立群组，以招聘为幌子，诱骗求职者下载不明软件并参与刷单活动，进而骗取求职者财物。此类行为严重侵害了我公司的合法权益，同时也给广大求职者带来了经济损失和精神伤害。为此，我公司特此发布以下郑重声明：</div>
				<div class="s-content">一、我公司的所有招聘活动均通过官方渠道进行，包括但不限于公司官网、官方招聘平台及经公司授权的第三方招聘网站。我公司从未通过QQ群或其他非官方社交平台进行招聘活动，也未授权任何个人或机构以公司名义建立招聘相关的QQ群组。</div>
				<div class="s-content">二、我公司不存在所谓的“试岗3天”制度，所有员工的入职流程均严格按照公司规定执行，包括但不限于面试、录用通知、入职培训等环节。我公司从未要求应聘者参与任何形式的刷单活动或下载非官方软件。</div>
				<div class="s-content">三、我公司在员工入职过程中，不会收取任何费用，包括但不限于报名费、培训费、押金等。任何以公司名义要求应聘者支付费用的行为，均属诈骗行为，请广大求职者务必提高警惕，切勿上当受骗。</div>
				<div class="s-content">四、针对上述不法行为，我公司将积极配合警方调查取证，追究犯罪分子的法律责任。同时，我公司也提醒广大求职者，在求职过程中务必保持警惕，认真核实招聘信息的真实性和可靠性，避免遭受经济损失。</div>
				<div class="s-content">五、我公司将持续关注并打击此类假冒招聘行为，维护公司声誉和求职者权益。如有任何疑问或发现类似情况，请及时与公司官方联系或向当地公安机关报案。</div>
				<div class="s-content">特此声明。</div>
				<div class="s-right-text">北京搜知数据科技有限公司</div>
				<div class="s-right-text">二〇二四年七月三十日</div>
			</div>
				<!-- <img class="statement" src="@/assets/statement.jpg"> -->
		</a-modal>
    </div>
</template>

<script>
import axios from "axios";
import Rollnumber from './commons/Rollnumber.vue';
import {
  config,
  thousandSplit,
	ajaxGet,
  getCookie,
  urlSoz
} from "../until/common.js";
import configUrl from '../until/configs';
import Header from "./commons/Header.vue";
import Footer from "./commons/Footer.vue";
import Search from "./commons/Search.vue";
export default {
	name:'Index',
	components: {
		Header,
		Footer,
		Search,
		Rollnumber
	},
	data() {
		let yearlist = [];
		let currentYear = new Date().getFullYear();
		for(let i=currentYear;i>2019;i--) {
			yearlist.push({name:i+''})
		}
		return {
			urlData:'',
			showAdv:true,
			hotNavs:'',
			eps_sid:'',
			epsUrl:configUrl.baseUrl[1],
			zhituUrl:configUrl.baseUrl[2],
			dataEPSobj:{},
			bannerback:{},
			epsData:require('../../public/json/EpsCubes.json'),
			cityCubes:require('../../public/json/CityCubes.json'),
			NewdataCubes:require('../../public/json/NewdataCubes.json'),
			weiguanData:require('../../public/json/WeiguanCubes.json'),
			publicSearchData:require('../../public/json/PublicSearchCubes.json'),
			baseBannerUrl:config.baseBannerUrl,
			rollNum:'123,456',
			paperYearsObj:currentYear+'',
			paperListdata: [
				{ id: 1, name: "2021" },
				{ id: 2, name: "2020" },
				{ id: 3, name: "2019" },
				{ id: 4, name: "2018" },
				{ id: 5, name: "2017" },
				{ id: 6, name: "2016" }
			],
			dataTopList: [
				...yearlist,
				{ name: "2019", num: "1050518607" },
				{ name: "2018", num: "370055019" }
			],
			dataTopYearsObj:{},
			lineDataList:[],
			downTop2019: [
				{
					childCubeId: 627,
					CUBEID: 627,
					NAME: "中国城市数据库",
					SUMQ: 198963783,
					TOP: 1
				},
				{
					childCubeId: 774,
					CUBEID: 304,
					NAME: "中国区域经济数据库",
					SUMQ: 112480586,
					TOP: 2
				},
				{
					childCubeId: 1190,
					CUBEID: 1190,
					NAME: "世界农林数据库",
					SUMQ: 63438748,
					TOP: 3
				},
				{
					childCubeId: 891,
					CUBEID: 9,
					NAME: "中国宏观经济数据库",
					SUMQ: 58562250,
					TOP: 4
				},
				{
					childCubeId: 1260,
					CUBEID: 1260,
					NAME: "世界卫生数据库",
					SUMQ: 58003391,
					TOP: 5
				},
				{
					childCubeId: 991,
					CUBEID: 990,
					NAME: "中国工业企业数据库",
					SUMQ: 57998864,
					TOP: 6
				},
				{
					childCubeId: 1203,
					CUBEID: 560,
					NAME: "中国财政税收数据库",
					SUMQ: 52049985,
					TOP: 7
				},
				{
					childCubeId: 23,
					CUBEID: 24,
					NAME: "中国商品贸易数据库",
					SUMQ: 37841836,
					TOP: 8
				}
			],
			downTop2018: [
				{
					childCubeId: 627,
					CUBEID: 627,
					NAME: "中国城市数据库",
					SUMQ: 65448770,
					TOP: 1
				},
				{
					childCubeId: 774,
					CUBEID: 304,
					NAME: "中国区域经济数据库",
					SUMQ: 39335980,
					TOP: 2
				},
				{
					childCubeId: 1190,
					CUBEID: 1190,
					NAME: "世界农林数据库",
					SUMQ: 23654293,
					TOP: 3
				},
				{
					childCubeId: 891,
					CUBEID: 9,
					NAME: "中国宏观经济数据库",
					SUMQ: 22190955,
					TOP: 4
				},
				{
					childCubeId: 991,
					CUBEID: 990,
					NAME: "中国工业企业数据库",
					SUMQ: 20363657,
					TOP: 5
				},
				{
					childCubeId: 1260,
					CUBEID: 1260,
					NAME: "世界卫生数据库",
					SUMQ: 20301187,
					TOP: 6
				},
				{
					childCubeId: 1203,
					CUBEID: 560,
					NAME: "中国财政税收数据库",
					SUMQ: 19808682,
					TOP: 7
				},
				{
					childCubeId: 23,
					CUBEID: 24,
					NAME: "中国商品贸易数据库",
					SUMQ: 16950879,
					TOP: 8
				}
			],
			news:[],

			navIndex:1,
			productApplicatList:[],
			analysisReportList:[],
			dataHeadlinesList:[],
			productTrendsList:[],
			dataupdateList:[],
			firstDataList:[],
			classOption:{
				step: 0.5,
				limitMoveNum:2,
				openTouch: false,
			},
			classOption2:{
				step: 0.5,
				direction: 2,
				limitMoveNum:2,
				openTouch: false,
			},
			newSideList:[],
			qikans:[
				{
					id:1300,
					pic:require('../assets/qikan1.jpg'),
				},
				{
					id:916,
					pic:require('../assets/qikan2.jpg'),
				},
				{
					id:950,
					pic:require('../assets/qikan3.jpg'),
				},
				{
					id:1549,
					pic:require('../assets/qikan4.jpg'),
				},
				{
					id:1276,
					pic:require('../assets/qikan5.jpg'),
				},				
				{
					id:663,
					pic:require('../assets/qikan6.jpg'),
				},
				{
					id:988,
					pic:require('../assets/qikan7.jpg'),
				},
				{
					id:1538,
					pic:require('../assets/qikan8.jpg'),
				},
				{
					id:1511,
					pic:require('../assets/qikan9.jpg'),
				},
				{
					id:1540,
					pic:require('../assets/qikan10.jpg'),
				},
				{
					id:1402,
					pic:require('../assets/qikan11.jpg'),
				},
				{
					id:1486,
					pic:require('../assets/qikan12.jpg'),
				},
				{
					id:1434,
					pic:require('../assets/qikan13.jpg'),
				},
				{
					id:1468,
					pic:require('../assets/qikan14.jpg'),
				},
				{
					id:1481,
					pic:require('../assets/qikan15.jpg'),
				},
				{
					id:1547,
					pic:require('../assets/qikan16.jpg'),
				},
				{
					id:1072,
					pic:require('../assets/qikan17.jpg'),
				},
				{
					id:1528,
					pic:require('../assets/qikan18.jpg'),
				},
				{
					id:1021,
					pic:require('../assets/qikan19.jpg'),
				},
				{
					id:896,
					pic:require('../assets/qikan20.jpg'),
				}
			],
			visible:false
		}
	},
	computed:{
		zhitu_sid() {
			return getCookie('zhitu_sid');
		}
	},
	methods:{
		tipsMessage() {
			this.$Message.warning('敬请期待！');
		},
		autoPlay() {
			this.activeClass++;
			if (this.activeClass > this.newSideList.length - 1) {
				this.activeClass = 0;
			}
		},
		getYearInit(num) {
			// 2018年的数据乘以2
			if (this.currentYear === "2018") {
				num = num * 2;
			}
			this.rollNum = "";
			this.rollNum = this.thousandSplit(num);
		},
		thousandSplit(num) {
			return thousandSplit(Math.round(num));
		},
		getTimerInit() {
			return setInterval(() => {
				if (this.dataTopYearsObj == '2021') {
					this.getDownData();
				}
			}, 10000); //5秒执行请求一次接口
		},
		getDownData() {
			var that = this;
			var params = { year: that.dataTopYearsObj };
			if (params.year == '2018') {
				that.downTop2018.forEach(function(v) {
				v.sum = ((v.SUMQ / that.downTop2018[0].SUMQ) * 100).toFixed(2);
				});
				that.downTop2018.forEach(function(v) {
				v.SUMQ = v.SUMQ * 2;
				});
				that.lineDataList2018 = that.downTop2018;
				that.getYearInit(that.dataTopYearsObj);
			}else if (params.year == '2019') {
				that.downTop2019.forEach(function(v) {
					v.sum = ((v.SUMQ / that.downTop2019[0].SUMQ) * 100).toFixed(2);
				});
				that.lineDataList2019 = that.downTop2019;
				that.getYearInit(that.dataTopYearsObj);
			} else {
				ajaxGet("getDownloadTop", params).then(res => {
				that.rullNumber = res.data.count;
				if (res && res.data) {
					that.maxCounts = res.data.maxCount;
					that.rollNum = "";
					that.rollNum = that.thousandSplit(res.data.count.toString());
					res.data.topList.forEach(function(v) {
						v.sum = ((v.SUMQ / res.data.maxCount) * 100).toFixed(2);
						v.childCubeId = v.CUBEID;
						v.name = v.NAME
						if(v.NAME.length > 9){
							v.name = v.NAME.slice(0,9) + "...";
						}
					});
					that.lineDataList = res.data.topList.slice(0,8);
				}
				});
			}
		},
		selectedProvinceObj: function(v) {
			var that = this;
			that.dataTopYearsObj = v;
			that.currentYear = v;
			if (v === "2019") {
				let sum=0;
				that.downTop2019.forEach(function(v) {
					v.sum = ((v.SUMQ / that.downTop2019[0].SUMQ) * 100).toFixed(2);
					sum+=v.SUMQ;
				});
				that.lineDataList2019 = that.downTop2019;
				// that.getYearInit(that.dataTopYearsObj);
				this.rollNum = this.thousandSplit(sum);
			} else if (v === "2018") {
				let sum=0;
				that.downTop2018.forEach(function(v) {
					v.sum = ((v.SUMQ / that.downTop2018[0].SUMQ) * 100).toFixed(2);
					sum+=v.SUMQ;
				});
				that.downTop2018.forEach(function(v) {
					v.SUMQ = v.SUMQ * 2;
				});
				that.lineDataList2018 = that.downTop2018;
				this.rollNum = this.thousandSplit(sum);
				//that.getYearInit(that.dataTopYearsObj);
			}else{
				that.getDownData();
			}
		},
		setFixedNav(num) {
			this.navIndex = num;
			if(num===1) {
				window.scrollTo(0,0);
			}else if(num===2) {
				window.scrollTo(0,1100);
			}else if(num===3) {
				window.scrollTo(0,1560);
			}else if(num===4) {
				window.scrollTo(0,2030);
			}else if(num===5) {
				window.scrollTo(0,2500);
			}
		}
	},
	created() {
		if(document&&document.location.hostname===urlSoz){
            this.urlData = 'sozdata'
        }else{
            this.urlData = 'epsdata'
        }
		this.eps_sid = getCookie('eps_sid');
	},
	mounted() {
		// if (getCookie('stateModal')) {
		// 	this.visible = false
		// } else {
		// 	this.visible = true
		// 	const time = new Date(new Date().toLocaleDateString()).getTime()+ 3600 * 1000 * 24 - 1000
		// 	setCookie('stateModal','effective',time)
		// }
		var that = this;
		// 搜索背景图的接口
		axios.get(config.baseBannerUrl + '/hotMenu/api_list',{params:{menuStatus:1}}).then(({data})=>{
			if(data.data) {
				this.hotNavs = data.data;
			}
		})
		axios.get(config.baseBannerUrl + "/banner/getAll?CLASSIFY_CODE=11&SB=0").then(res => {
			if(res&&res.data&&res.data.data){
				this.newSideList = res.data.data;
				this.bannerback = res.data.datasb.length>0?res.data.datasb[0]:{};
			}
		});
		ajaxGet("cubeStatNums").then(res => {
			if (res&&res.data) {
				this.dataEPSobj = res.data;
			}
		});


		//默认展示年份列表的第一个数据(下载排行和论文引用)
		that.dataTopYearsObj = that.dataTopList[0].name;
		that.paperYearsObj = that.paperListdata[0].name;
		if (that.dataTopYearsObj === '2021') {
			that.getTimerInit(); //下载数据（下载量）每5秒执行一次
			that.getDownData(); //下载数据列表+下载排行板块的滚动数字
		}
		//that.getProductsList(); //产品应用列表
		//that.getAnalysisList(); //分析报告列表
		that.$nextTick(() => {
			that.timer = setInterval(() => {
				that.autoPlay();
			}, 10000);
		});

		axios.get(config.baseBannerUrl + "/banner/getAll?CLASSIFY_CODE=11&SB=0").then(res => {
			if(res&&res.data&&res.data.data){
				this.newSideList = res.data.data;
			}
		});

		axios
		.get(config.baseUrl + "api/comgedata?sp=EPSZH_HOMEPAGE_2020&p=&v=")
		.then(res => {
			/*
				左一模块
			*/
			var gData1 = [];
			gData1.push(res.data.result[0][0]); //listData.Table[0]
			that.productApplicatList = gData1; //产品应用
			var gData2 = [];
			gData2.push(res.data.result[0][1]); //listData.Table[1]
			that.analysisReportList = gData2; //分析报告
			/*
				左二模块
			*/

			that.dataHeadlinesList = res.data.result[1].slice(0,5); //listData.Table1;//数据头条
			that.productTrendsList = res.data.result[2].slice(0,5); //listData.Table2;//产品公告(产品动态)
			/*
				右二模块
			*/

			res.data.result[3].forEach(function(v) {
				// 处理（lastmodtime字段）发布时间的格式
				var str = v.lastmodtime.substr(5, 5);
				var strs = str.replace(/\s/g, "");
				var arr = strs.split("-");
				v.lastmodtime = arr.join(".");
				// 处理（calender_lastdate字段）年度数据的时间格式
				if (v.calender_dym == 1) {
				var str_1 = v.calender_lastdate.substr(0, 4);
				v.calender_lastdate = str_1;
				}
				// 处理（calender_lastdate字段）季度数据的时间格式
				if (v.calender_dym == 3) {
				var str1 = v.calender_lastdate.substr(0, 5);
				var str2 = v.calender_lastdate.substr(5, 2);
				if (str2 == "01") {
					v.nums = "I";
				} else if (str2 == "04") {
					v.nums = "II";
				} else if (str2 == "07") {
					v.nums = "III";
				} else if (str2 == "10") {
					v.nums = "IV";
				}
				v.calender_lastdate = str1 + v.nums;
				}
				// 处理（calender_lastdate字段）月度数据的时间格式
				if (v.calender_dym == 4) {
				var str_2 = v.calender_lastdate.substr(0, 7);
				v.calender_lastdate = str_2;
				}
			});
			that.dataupdateList = res.data.result[3]; //数据更新
			res.data.result[7].forEach(function(v) {
				// 处理（lastmodtime字段）发布时间的格式
				var str = v.lastmodtime.substr(5, 5);
				var strs = str.replace(/\s/g, "");
				var arr = strs.split("-");
				v.lastmodtime = arr.join(".");
				if (v.calender_dym == 1) {
				var str_1 = v.calender_lastdate.substr(0, 4);
				v.calender_lastdate = str_1;
				}
			});
			that.firstDataList = res.data.result[7]; //全网首发
			//that.marqueelist = that.dataupdateList;
		});

		axios.get(config.baseUrl + 'api/comgedata?sp=News_GetList&p=PageIndex;newsitem&v=1;7').then(res=>{
			if(res.data) {
				this.news = res.data.result[0].slice(0,7);
			}
		})
		window.onscroll=()=>{
			var scroll_top = 0;
			if (document.documentElement && document.documentElement.scrollTop) {
				scroll_top = document.documentElement.scrollTop;
			}
			else if (document.body) {
				scroll_top = document.body.scrollTop;
			}
			if(0<=scroll_top&&scroll_top<1100) {
				this.navIndex=1;
			} else if(1100<=scroll_top&&scroll_top<1560) {
				this.navIndex=2;
			} else if(1560<=scroll_top&&scroll_top<2030) {
				this.navIndex=3;
			} else if(2030<=scroll_top&&scroll_top<2500) {
				this.navIndex=4;
			} else {
				this.navIndex=5;
			}
		}
		
	}
}
</script>

<style lang="scss" scoped>
@import '@/scss/_index.scss';
.fixed-adv {
	width:200px;
	height:200px;
	position: fixed;
	top:100px;
	right:0;
	z-index: 99;
	padding:34px 10px 0;
	.anticon-close-circle {
		position: absolute;
		right:10px;
		top:10px;
		cursor: pointer;
	}
	img {
		width:100%;
	}
	* {
		color:#999;
	}
}
.state{
	.s-head {
		border-bottom: 1px solid #999;
		padding-bottom: 8px;
		img{
			height: 30px;
			&:first-child{
				margin-right: 8px;
			}
			&:last-child{
				height: 38px;
			}
		}
	}
	.s-title {
		font-size: 24px;
		font-weight: 600;
		text-align: center;
		margin: 3% 0 2%;
	}
	.s-content {
		font-size: 16px;
		text-indent: 2em;
		line-height: 32px;
	}
	.s-right-text {
		text-align: right;
		font-size: 16px;
		line-height: 32px;
	}
}
</style>