<template>
    <!-- <div class="rollNumber">
        <div v-for="(item,index) in numArr" :key="index" class="nums cleft" :class="{str:(typeof item)!='number'}">
            <div class="numItem" v-if="(typeof item)=='number'" :style="styles[index]">0<br/>1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9</div>
            <div class="numItems"  v-if="item==','">,</div>
            <div class="numItem"  v-if="item=='-'">-</div>
        </div>
    </div> -->
    <div>
        <div v-for="(item,index) in numArr" :key="index" class="nums" :class="{str:(typeof item)!='number'}">
            <div class="numItem" v-if="(typeof item)=='number'" :style="styles[index]">
                <!-- 0<br/>1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9 -->
                <span>0</span>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
                <span>7</span>
                <span>8</span>
                <span>9</span>
            </div>
            <div class="numItem"  v-if="item==','">,</div>
            <div class="numItem"  v-if="item=='-'">-</div>
        </div>
    </div>
</template>

<script>
export default {
    name:'rollNum',
    data:function() {
        return {            
            styles:[{},{},{},{}],
            numArr:[0,',',0,0,0]
        }
    },
    props:['numStr'],
    watch:{
        /* numStr() {
            this.styles = [];
            this.numArr = [];
            for(var i=0;i<this.numStr.length;i++) {
                var a = this.numStr.substring(i,i+1);
                if(parseInt(a).toString()!='NaN') {
                    this.numArr.push(parseInt(a));
                    this.styles.push({marginTop:'-'+parseInt(a)*37+'px',transition:'all 1s ease-in-out'});
                } else {
                    this.numArr.push(a);
                    this.styles.push({})
                }
            }
        } */
        numStr() {
            this.styles = [];
            this.numArr = [];
            for(var i=0;i<this.numStr.length;i++) {
                var a = this.numStr.substring(i,i+1);
                if(parseInt(a).toString()!='NaN') {
                    this.numArr.push(parseInt(a));
                    this.styles.push({marginTop:'-'+parseInt(a)*34+'px',transition:'all 1s ease-in-out'});
                } else {
                    this.numArr.push(a);
                    this.styles.push({})
                }
                
            }
        }
    }
}
</script>

<style lang="scss">
.nums {
    // display: inline-block;
    float: left;
    width:22px;
    height:34px;
    overflow:hidden;
    text-align: center;
    background: #204f7a;
    margin:0 3px;
    border-radius: 3px;
    
    // box-shadow: 1px 1px 10px 3px #dadada;
    // -webkit-box-shadow: 1px 1px 10px 3px #dadada;
    // -moz-box-shadow: 1px 1px 10px 3px #dadada;
    // line-height:35px;
    // font: 20px/35px Arial;
    // color: #04e1ed;
}
.nums.str {
    background:none;
    width:10px;
    margin:0;
    // box-shadow: 1px 1px 10px 3px transparent;
    // -webkit-box-shadow: 1px 1px 10px 3px transparent;
    // -moz-box-shadow: 1px 1px 10px 3px transparent;
    .numItem {
        font-family: 'Microsoft yahei';
        font-size:26px;
    }
}
.numItem {
    display:block;
    width:100%;
    height:100%;
    text-align: center;
    color: #04e1ed;
    span{
        display:block;
        height:34px;
        width:100%;
        color: #04e1ed;
        font-size: 22px;
    }
}
</style>
